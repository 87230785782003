<template>
  <div 
    class="bouncy-image" 
    ref="imageContainer"
    @mousedown.prevent="startDrag"
    @touchstart.prevent="startDrag"
    draggable="false"
    :class="{ 'is-dragging': isDragging }"
  >
    <img :src="src" :style="{ width: `${size}px`, height: `${size}px` }" alt="Floating Image" draggable="false" />
  </div>
</template>

<script>
import Matter from "matter-js";

export default {
  props: {
    src: String,
    engine: Object,
    size: {
      type: Number,
      default: 90
    }
  },
  data() {
    return {
      circle: null,
      isDragging: false,
      mouseConstraint: null
    }
  },
  mounted() {
    this.createPhysics();
  },
  methods: {
    createPhysics() {
      if (!this.engine) return;
      
      const { World, Bodies, Body } = Matter;

      // Random physics parameters for variety
      const restitution = 0.5 + Math.random() * 0.5;  // 0.5 to 1.0
      const frictionAir = 0.0005 + Math.random() * 0.001; // 0.0005 to 0.0015
      const initialSpeed = 1 + Math.random() * 4; // 1 to 5

      // Create a circle with randomized physics
      this.circle = Bodies.circle(
        window.innerWidth * 0.5 + (Math.random() - 0.5) * 200,
        window.innerHeight * 0.5 + (Math.random() - 0.5) * 200,
        this.size / 2,  // radius is half the size
        {
          restitution: restitution,
          frictionAir: frictionAir,
          friction: 0.0001,
          density: 0.001,
          collisionFilter: {
            category: 0x0001,
            mask: 0x0001
          }
        }
      );

      // Initial velocity with random speed
      Body.setVelocity(this.circle, {
        x: (Math.random() - 0.5) * initialSpeed,
        y: (Math.random() - 0.5) * initialSpeed
      });

      World.add(this.engine.world, this.circle);

      // Bubbly movement with varied force
      const addBubblyForce = () => {
        if (this.$refs.imageContainer && !this.isDragging) {
          const forceMultiplier = 0.0001 + Math.random() * 0.0002;
          Body.applyForce(this.circle, this.circle.position, {
            x: (Math.random() - 0.5) * forceMultiplier,
            y: -0.00005 + (Math.random() - 0.5) * forceMultiplier
          });
        }
        setTimeout(addBubblyForce, 1000 + Math.random() * 1000); // Random interval between 1-2s
      };

      addBubblyForce();

      // Position updates
      const update = () => {
        if (this.$refs.imageContainer) {
          const rotation = this.circle.angle * (180 / Math.PI);
          this.$refs.imageContainer.style.transform = 
            `translate3d(${~~this.circle.position.x}px, ${~~this.circle.position.y}px, 0) rotate(${~~rotation}deg)`;
        }
        setTimeout(() => requestAnimationFrame(update), 16); // Cap at ~60fps
      };

      update();
    },

    startDrag(e) {
      if (!this.circle) return;
      
      this.isDragging = true;
      const mouseEvent = e.touches ? e.touches[0] : e;
      
      // Calculate offset from click position to circle center
      const offset = {
        x: mouseEvent.clientX - this.circle.position.x,
        y: mouseEvent.clientY - this.circle.position.y
      };

      let lastPosition = { x: mouseEvent.clientX, y: mouseEvent.clientY };
      let lastTime = Date.now();
      
      const handleDrag = (e) => {
        const mouseEvent = e.touches ? e.touches[0] : e;
        const currentTime = Date.now();
        const deltaTime = Math.max(currentTime - lastTime, 16); // Cap minimum delta
        
        // Calculate velocity from movement
        const velocity = {
          x: (mouseEvent.clientX - lastPosition.x) / deltaTime * 10,
          y: (mouseEvent.clientY - lastPosition.y) / deltaTime * 10
        };
        
        // Update position considering the offset
        Matter.Body.setPosition(this.circle, {
          x: mouseEvent.clientX - offset.x,
          y: mouseEvent.clientY - offset.y
        });
        
        lastPosition = { x: mouseEvent.clientX, y: mouseEvent.clientY };
        lastTime = currentTime;
        
        // Store the current velocity
        this.circle.velocity = velocity;
      };

      const stopDrag = () => {
        this.isDragging = false;
        
        // Apply the stored velocity on release
        if (this.circle.velocity) {
          // Scale the throw velocity based on movement speed
          const speed = Math.sqrt(
            this.circle.velocity.x * this.circle.velocity.x + 
            this.circle.velocity.y * this.circle.velocity.y
          );
          
          if (speed > 0.1) { // Only apply throw velocity if there was significant movement
            Matter.Body.setVelocity(this.circle, {
              x: this.circle.velocity.x,
              y: this.circle.velocity.y
            });
          } else {
            // Reset to gentle floating if barely moving
            Matter.Body.setVelocity(this.circle, {
              x: (Math.random() - 0.5) * 2,
              y: (Math.random() - 0.5) * 2
            });
          }
        }
        
        window.removeEventListener('mousemove', handleDrag);
        window.removeEventListener('mouseup', stopDrag);
        window.removeEventListener('touchmove', handleDrag);
        window.removeEventListener('touchend', stopDrag);
      };

      window.addEventListener('mousemove', handleDrag);
      window.addEventListener('mouseup', stopDrag);
      window.addEventListener('touchmove', handleDrag);
      window.addEventListener('touchend', stopDrag);
    }
  }
};
</script>

<style scoped>
.bouncy-image {
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.05s linear, filter 0.2s ease;
  cursor: grab;
  user-select: none;
  -webkit-user-drag: none;
  touch-action: none;
  filter: drop-shadow(0 10px 15px rgba(0,0,0,0.3));
  perspective: 1000px;
  transform-style: preserve-3d;
}

.bouncy-image.is-dragging {
  z-index: 100;
  filter: drop-shadow(0 20px 25px rgba(0,0,0,0.4));
}

.bouncy-image:active {
  cursor: grabbing;
}

.bouncy-image img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  transform: translateZ(20px);
  box-shadow: 
    0 5px 15px rgba(0,0,0,0.2),
    0 0 40px rgba(0,0,0,0.1);
  transition: transform 0.2s ease;
  backface-visibility: hidden;
  -webkit-user-drag: none;
  user-select: none;
  pointer-events: none;
}

.bouncy-image:hover img {
  transform: translateZ(30px) scale(1.1);
}

.bouncy-image.is-dragging img {
  transform: translateZ(50px) scale(1.15);
}
</style>
