<template>
  <div class="app" ref="app">
    <div class="text-wrapper">
      <div class="text">FLY</div>
    </div>
    <div v-if="engineReady">
      <BouncyImage 
        v-for="(image, index) in images" 
        :key="index" 
        :src="image.src"
        :size="image.size"
        :engine="engine"
        :mousePosition="mousePosition"
      />
    </div>
  </div>
</template>

<script>
import { Engine, Runner, World, Bodies } from 'matter-js';
import BouncyImage from './components/BouncyImage.vue';

export default {
  components: { BouncyImage },
  data() {
    return {
      images: [
        { src: '/images/1.png', size: 140 },
        { src: '/images/1.png', size: 110 },
        { src: '/images/1.png', size: 80 },
        { src: '/images/1.png', size: 60 },
        { src: '/images/1.png', size: 140 },
        { src: '/images/1.png', size: 110 },
        { src: '/images/1.png', size: 80 },
        { src: '/images/1.png', size: 60 },
        { src: '/images/2.png', size: 130 },
        { src: '/images/2.png', size: 100 },
        { src: '/images/2.png', size: 70 },
        { src: '/images/2.png', size: 50 },
        { src: '/images/3.png', size: 120 },
        { src: '/images/3.png', size: 90 },
        { src: '/images/3.png', size: 60 },
        { src: '/images/3.png', size: 130 },
        { src: '/images/3.png', size: 90 },
        { src: '/images/3.png', size: 60 },
        { src: '/images/3.png', size: 140 },
        { src: '/images/3.png', size: 90 },
        { src: '/images/3.png', size: 60 },
        { src: '/images/3.png', size: 150 },
        { src: '/images/3.png', size: 90 },
        { src: '/images/3.png', size: 60 },
        { src: '/images/5.gif', size: 125 },
        { src: '/images/5.gif', size: 95 },
        { src: '/images/5.gif', size: 65 },
        { src: '/images/8.png', size: 115 },
        { src: '/images/8.png', size: 85 },
        { src: '/images/8.png', size: 55 },
        { src: '/images/8.png', size: 115 },
        { src: '/images/8.png', size: 85 },
        { src: '/images/8.png', size: 55 },
        { src: '/images/7.png', size: 135 },
        { src: '/images/7.png', size: 105 },
        { src: '/images/7.png', size: 75 },
        { src: '/images/7.png', size: 135 },
        { src: '/images/7.png', size: 105 },
        { src: '/images/7.png', size: 75 },
      ],
      engine: null,
      runner: null,
      engineReady: false,
      mousePosition: { x: 0, y: 0 },
      lastMousePosition: { x: 0, y: 0 },
      isThrottled: false,
      tiltFrame: null,
      lastUpdate: 0,
      updateInterval: 1000 / 60 // target 60fps
    };
  },
  mounted() {
    this.setupMatter();
    this.engineReady = true;
    window.addEventListener('mousemove', this.handleMouseMove);
    
    if (process.env.NODE_ENV === 'development') {
      import('stats.js').then(({ default: Stats }) => {
        const stats = new Stats();
        stats.showPanel(0);
        stats.dom.style.cssText = 'position:fixed;left:0;top:0;z-index:10000';
        document.body.appendChild(stats.dom);
        
        const animate = () => {
          stats.begin();
          stats.end();
          requestAnimationFrame(animate);
        };
        
        requestAnimationFrame(animate);
      });
    }
  },
  methods: {
    setupMatter() {
      this.engine = Engine.create({
        gravity: { x: 0, y: 0 },
        enableSleeping: true,
        constraintIterations: 1,
        positionIterations: 1,
        velocityIterations: 1
      });
      
      this.runner = Runner.create();
      Runner.run(this.runner, this.engine);

      const thickness = 60;
      const boundaries = [
        Bodies.rectangle(window.innerWidth/2, -thickness/2, window.innerWidth, thickness, { isStatic: true }),
        Bodies.rectangle(window.innerWidth/2, window.innerHeight + thickness/2, window.innerWidth, thickness, { isStatic: true }),
        Bodies.rectangle(-thickness/2, window.innerHeight/2, thickness, window.innerHeight, { isStatic: true }),
        Bodies.rectangle(window.innerWidth + thickness/2, window.innerHeight/2, thickness, window.innerHeight, { isStatic: true })
      ];
      
      World.add(this.engine.world, boundaries);
    },
    handleMouseMove(e) {
      this.mousePosition = { x: e.clientX, y: e.clientY };
      
      if (this.isThrottled) return;
      this.isThrottled = true;

      requestAnimationFrame(() => {
        const x = (e.clientX / window.innerWidth) * 100;
        const y = (e.clientY / window.innerHeight) * 100;
        
        this.$refs.app.style.setProperty('--mouse-x', `${x}`);
        this.$refs.app.style.setProperty('--mouse-y', `${y}`);
        this.isThrottled = false;
      });
    }
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
    if (this.runner) {
      Runner.stop(this.runner);
    }
  }
};
</script>

<style>
.app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: linear-gradient(
    135deg,
    #DF88EA 0%,
    #f4b6ff 25%,
    #DF88EA 50%,
    #c76dd3 75%,
    #DF88EA 100%
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.text-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  pointer-events: none;
  transform-style: preserve-3d;
}

.text {
  font-size: 14vw;
  font-weight: 900;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  text-align: center;
  -webkit-text-stroke: 5px #DF88EA;
  filter: drop-shadow(0 5px 15px rgba(0,0,0,0.4))
         drop-shadow(0 0 40px rgba(223,136,234,0.6));
  transform: 
    perspective(500px)
    rotateX(calc((var(--mouse-y) - 50) * 0.7deg))
    rotateY(calc((var(--mouse-x) - 50) * -0.7deg))
    translateZ(50px);
}

.text-mask {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  pointer-events: none;
  mix-blend-mode: difference;
  font-size: 14vw;
  font-weight: 900;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  text-align: center;
  -webkit-text-stroke: 3px #DF88EA;
  text-stroke: 3px #DF88EA;
}
</style>